@import 'vars';

.scrollbar {
  overflow: auto; // To let lazyload realize the container
  &:hover {
    overflow: overlay; // Avoid scrollbar occupying space
  }
}

// Hide all inessential
::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: adjust-color($PRIMARY, $alpha: -0.9);
}
::-webkit-scrollbar-track-piece {
  display: block;
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-corner {
  display: block;
  width: 4px;
  height: 4px;
  background: transparent;
}

// Customize the bar
:hover::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
:hover::-webkit-scrollbar-thumb {
  background: adjust-color($PRIMARY, $alpha: -0.4);
  border-radius: 4px;
  &:hover {
    background: $PRIMARY;
  }
}

// Hide all inessential
.scrollbar::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
  background: transparent;
}
.scrollbar::-webkit-scrollbar-track-piece {
  display: block;
  width: 4px;
  height: 4px;
  background: transparent;
}
.scrollbar::-webkit-scrollbar-corner {
  display: block;
  width: 4px;
  height: 4px;
  background: transparent;
}

// Customize the bar
.scrollbar:hover::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
  background: adjust-color($PRIMARY, $alpha: -0.9);
}
.scrollbar:hover::-webkit-scrollbar-thumb {
  background: adjust-color($PRIMARY, $alpha: -0.4);
  border-radius: 4px;
  &:hover {
    background: adjust-color($PRIMARY, $alpha: -0.4);
  }
}
