@import './vars';

/**
 * Button
 */

.space-3-btn-primary {
  color: $TEXT_COLOR_DARK !important;

  &:has(.btn-clip-path) {
    filter: drop-shadow(2px 2px 0.5px adjust-color($PRIMARY, $alpha: -0.6));
  }

  &:hover {
    background: #0db8af !important;
    filter: drop-shadow(-2px -2px 1px 0px rgba(0, 0, 0, 0.7) inset);
  }

  &[disabled] {
    border: transparent;
    color: black !important;
    background: #bfbfbf;

    &:hover {
      background: #bfbfbf !important;
    }
  }
}

/**
 * Notification
 */
.space-3-notification {
  &-notice {
    background: transparent !important;
    border-radius: 8px !important;
    width: unset !important;
    min-width: 259px;
    clip-path: polygon(7.5% 0, 100% 0, 100% 65%, 92.5% 100%, 0 100%, 0 35%);

    &-wrapper {
      background: transparent !important;
      box-shadow: unset !important;
      clip-path: polygon(7.5% 0, 100% 0, 100% 65%, 92.5% 100%, 0 100%, 0 35%);
      backdrop-filter: blur(25px);
    }

    &-message {
      font-size: 17px !important;
    }

    &-success {
      background: adjust-color(#1eaf6a, $alpha: -0.92) !important;

      .space-3-notification-notice-icon-success {
        color: #1eaf6a !important;
      }

      &-outline {
        &:before {
          background: url('/images/border-notice-success.svg') no-repeat center;
        }
      }
    }

    &-warning {
      background: adjust-color(#ff8d2f, $alpha: -0.92) !important;

      .space-3-notification-notice-icon-warning {
        color: #ff8d2f !important;
      }

      &-outline {
        &:before {
          background: url('/images/border-notice-warning.svg') no-repeat center;
        }
      }
    }

    &-error {
      background: adjust-color(#e64848, $alpha: -0.92) !important;

      .space-3-notification-notice-icon-error {
        color: #e64848 !important;
      }

      &-outline {
        &:before {
          background: url('/images/border-notice-error.svg') no-repeat center;
        }
      }
    }

    &-message {
      margin-bottom: 0 !important;
    }

    .space-3-notification-notice-close {
      background: transparent;
      border-radius: 50% !important;
      width: 24px !important;
      height: 24px !important;

      .space-3-notification-close-x {
        color: $SECONDARY;
        font-size: 14px;
      }

      &:hover {
        background: #a0a0a010 !important;
      }
    }

    &-with-icon:not(:has(.space-3-notification-notice-description:empty)) {
      .space-3-notification-notice-icon {
        margin-top: 5px;
      }
    }
  }

  // outline

  div[class*='outline'] {
    overflow: inherit !important;
    position: relative;

    &:before {
      position: absolute !important;
      content: '';
      top: 0;
      left: 0;
      width: 100% !important;
      height: calc(100% - 1px) !important;
      background-size: 100% 100%;
    }
  }
}
