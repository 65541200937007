@import 'vars';

/**
* NProgress
*/

#nprogress .spinner {
  display: none !important;
}

#nprogress .bar {
  background: $PRIMARY;
}

#nprogress .peg {
  box-shadow:
    0 0 10px $PRIMARY,
    0 0 5px $PRIMARY;
}

#nprogress .spinner-icon {
  border-top-color: $PRIMARY;
  border-left-color: $PRIMARY;
}
