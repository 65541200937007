/**
* Archivo
*/

@font-face {
  font-family: Archivo;
  src: url('/fonts/archivo/archivo.ttf');
  font-style: normal;
}

@font-face {
  font-family: Archivo;
  src: url('/fonts/archivo/archivo-italic.ttf');
  font-style: italic;
}

/**
* DIN
*/

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-regular-italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-thin-italic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-extra-light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-extra-light-italic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-light-italic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-medium-italic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-bold-italic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: DIN;
  src: url('/fonts/din/din-black-italic.ttf');
  font-weight: 900;
  font-style: italic;
}
