a {
  transition: unset !important;
}

.space-3-typography {
  margin: 0 !important;
}

.font-expanded {
  font-stretch: expanded;
}
