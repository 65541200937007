@import './vars';
@import './fonts';
@import './typo';
@import './scrollbar';
@import './nprogress';
@import './antd';
@import './clip-path';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .admin-input {
    @apply py-2 px-4 border border-[#29333D] hover:border-solid;
  }
}

@layer utilities {
  .font-expanded {
    font-stretch: expanded;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

html {
  scroll-behavior: smooth;
  background: #060708;
}

body {
  position: relative !important;
  font-family: Archivo, sans-serif;
  overflow-x: hidden;

  &:before {
    content: '';
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(12px);
    background: url('/images/background.png') no-repeat center center;
    background-size: cover;
  }

  &:has(.admin-layout) {
    background: #070d0e !important;
  }
}

@media screen and (max-width: $MAX_WIDTH_MOBILE) {
  body {
    &:before {
      background: url('/images/background-mobile.png') no-repeat top center;
      background-size: cover;
    }
  }
}
